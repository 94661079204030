import { gsap } from 'gsap';
import animateHero from './helpers/gsap-animation';

const ANIMATION_DURATION_IN_MS = 2000;

function initHeroHomeAnimations() {
    const hero = document.querySelector('.hero-home');
    if (!hero) {
        return;
    }
    hero.classList.remove('no-animate');
    const timeline = gsap.timeline({ delay: 1 });
    const image = hero.querySelector('.hero-home__icon');
    const contentBlock = hero.querySelector('.hero-home__content');
    const contentItems = Array.from(contentBlock.children);
    animateHero(timeline, contentItems);
    timeline.eventCallback('onStart', () => {
        hero.classList.remove('animate-pending');
        onAnimationStart(image);
    });
}

function onAnimationStart(image) {
    image.classList.add('animate');
    setTimeout(() => {
        image.classList.remove('animate');
    }, ANIMATION_DURATION_IN_MS);
}

window.requestAnimationFrame(function() {
    initHeroHomeAnimations();
});
